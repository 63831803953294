import axios from "axios";
import { useEffect, useState } from "react";
import HNLogo from "../img/hn-logo.gif";
import Post from "./Post";

const PostList = ({ formData }) => {
  const [stories, setStories] = useState([]);

  useEffect(() => {
    axios
      .get("https://hacker-news.firebaseio.com/v0/topstories.json")
      .then((resp) => {
        if (resp.data.length >= 11) {
          const only10 = resp.data.slice(0, 10);

          const res = [];
          only10.map((item, idx) => {
            if (idx === 1) {
              res.push(0);
              res.push(item);
            } else {
              res.push(item);
            }
          });
          setStories(res);
        } else {
          const res = [];
          resp.data.map((item, idx) => {
            if (idx === 1) {
              res.push(0);
              res.push(item);
            } else {
              res.push(item);
            }
          });
          setStories(res);
        }
      });
  }, []);

  return (
    <div className="post-list-container">
      <div className="header">
        <div className="flex-center">
          <a
            href="https://news.ycombinator.com/"
            target="_blank"
            rel="noreferrer"
            className="flex-center"
          >
            <img src={HNLogo} alt="Hacker News Logo" /> Hacker News
          </a>
          <a
            href="https://news.ycombinator.com/newest"
            target="_blank"
            rel="noreferrer"
          >
            new
          </a>
          |
          <a
            href="https://news.ycombinator.com/newest"
            target="_blank"
            rel="noreferrer"
          >
            threads
          </a>
          |
          <a
            href="https://news.ycombinator.com/front"
            target="_blank"
            rel="noreferrer"
          >
            past
          </a>
          |
          <a
            href="https://news.ycombinator.com/newcomments"
            target="_blank"
            rel="noreferrer"
          >
            comments
          </a>
          |
          <a
            href="https://news.ycombinator.com/ask"
            target="_blank"
            rel="noreferrer"
          >
            ask
          </a>
          |
          <a
            href="https://news.ycombinator.com/show"
            target="_blank"
            rel="noreferrer"
          >
            show
          </a>
          |
          <a
            href="https://news.ycombinator.com/jobs"
            target="_blank"
            rel="noreferrer"
          >
            jobs
          </a>
          |
          <a
            href="https://news.ycombinator.com/submit"
            target="_blank"
            rel="noreferrer"
          >
            submit
          </a>
        </div>
      </div>
      <div className="posts-list-content">
        {stories.map((id, idx) => (
          <Post key={id} id={id} idx={idx} formData={formData} />
        ))}
      </div>
    </div>
  );
};

export default PostList;

import axios from "axios";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";

const validUrlRegex = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
);

const Post = ({ id, idx, formData }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if (id && id !== 0) {
      axios
        .get("https://hacker-news.firebaseio.com/v0/item/" + id + ".json")
        .then((resp) => {
          setData(resp.data);
        });
    }
  }, [id]);

  const getDomain = (url) => {
    try {
      if (url.length > 0) {
        if (!url.includes("http://") && !url.includes("https://")) {
          url = "https://" + url;
        }

        if (validUrlRegex.test(url)) {
          let domain = new URL(url);
          return domain.hostname.replace("www.", "");
        }
      }
    } catch {
      return "";
    }

    return "";
  };

  const validateUrl = (url) => {
    if (!url.includes("http://") && !url.includes("https://")) {
      return (url = "https://" + url);
    }

    return url;
  };

  if (!data.title && id !== 0) {
    return null;
  }

  return (
    <section className="post-container">
      <div className="arrow-container">
        {idx + 1}. <div className="arrow-up"></div>
      </div>
      {id === 0 && (
        <a
          href={validateUrl(formData.url) || "https://ae.studio"}
          target="_blank"
          rel="noreferrer"
        >
          <div className="title">
            {formData.title || "Add a Title: Visualize Your Post in this Spot"}{" "}
            <span className="link-title">
              ({getDomain(formData.url) || "visualizeHackerNews.com"})
            </span>
          </div>
          <div className="subtitle">
            25 points by {formData.user || "agencyenterprise"}{" "}
            <ReactTimeAgo date={Date.now()} locale="en-US" /> | 43 comments
          </div>
        </a>
      )}
      {id !== 0 && (
        <a href={data.url} target="_blank" rel="noreferrer">
          <div className="title">
            {data.title}{" "}
            <span className="link-title">({getDomain(data.url)})</span>
          </div>
          <div className="subtitle">
            {data.score} points by {data.by}{" "}
            <ReactTimeAgo date={data.time * 1000} locale="en-US" />|{" "}
            {data.descendants} comments
          </div>
        </a>
      )}
    </section>
  );
};

export default Post;

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { useState } from "react";
import "./App.css";
import Form from "./components/Form";
import PostList from "./components/PostList";

TimeAgo.addDefaultLocale(en);

function App() {
  const [formData, setFormData] = useState({
    title: "",
    url: "",
    user: "",
  });
  return (
    <div className="app-container">
      <Form formData={formData} setFormData={setFormData} />
      <PostList formData={formData} />
    </div>
  );
}

export default App;

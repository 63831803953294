const Form = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="form-container">
      <div className="header">
        <h1>Visualize your Post on Hacker News</h1>
        <span>
          Made with {"♥"} by{" "}
          <a href="https://ae.studio?utm_source=sds&utm_medium=referral&utm_campaign=visualizehackernews&utm_content=logo&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252" target="_blank" rel="noreferrer">
            AE Studio
          </a>
        </span>
      </div>
      <div className="form-content">
        <div className="input-container">
          <div className="label">title</div>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
        </div>
        <div className="input-container">
          <div className="label">url</div>
          <input
            type="text"
            name="url"
            value={formData.url}
            onChange={handleChange}
          />
        </div>
        <div className="input-container">
          <div className="label">user</div>
          <input
            type="text"
            name="user"
            value={formData.user}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Form;
